<div class="message-text-container">
  <div class="columns">
    <div class="column is-6">
      <h3>Message Text for

        <span *ngIf="currentNotification() && currentNotification().notificationType">
          {{currentNotification().notificationType.desc}}
        </span>
        <span>Notification</span>
      </h3>
    </div>
    <div class="column is-6">
      <div class="main-button-div is-pulled-left">
        <div class="dropdown is-active">
          <div class="dropdown-trigger" (click)="buttonClicked()">
            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
              <span>Insert...</span>
              <span class="icon is-small">
                <i class="fas fa-angle-down" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu" *ngIf= "isDisplayOn">
            <div class="dropdown-content">
              <ng-container *ngFor="let item of wildcardCategories; let i = index">
                <div class="variable-content ">
                  <a (click)="getWildcardsByCategory(item)" [ngClass]="{'fa-disabled': !isAnyElementFocused()}">
                    <div class="variable-desc">
                      <div class="sys-variable-text has-left-border">
                        <span>
                          {{item}}
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
                <hr class="dropdown-divider is-separator">
              </ng-container>

            </div>
          </div>
        </div>
      </div>
      <div class="main-button-div  cascaded-dropdown-content" *ngIf= "isDisplayOn">
        <div class="dropdown is-active " *ngIf="wildcardsToDisplay&&wildcardsToDisplay.length>0">
          <div class="dropdown-trigger">
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <ng-container *ngFor="let item of wildcardsToDisplay; let i = index">
                <div class="variable-content ">
                  <div class="variable-desc">
                    <div class="has-desc">
                      <a (click) ="moreInfo(item)">  <span>{{item.notificationWildcardDesc}}</span>
                        </a>
                    </div>
                    <a (click)="insertVariable($event, item)" [ngClass]="{'fa-disabled': !isAnyElementFocused()}">
                      <div class="sys-variable-text has-left-border">
                        <span>
                          {{item.notificationWildcard}}
                        </span>
                      </div>
              
                    </a>
                  </div>
                </div>
                <hr class="dropdown-divider is-separator">
              </ng-container>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="columns" *ngIf="currentNotification()">
    <div class="column is-1">
      <div class=" field is-horizontal ">
        <label>Subject</label>
      </div>
    </div>
    <div class="column is-11">
      <div class=" text-field-padding ">
        <div class=" is-horizontal">
          <p class="control " *ngIf="isEditingDisabled()" [innerHTML]="currentNotification().subject" #subject>
          </p>
          <div class="control" *ngIf="!isEditingDisabled()">
            <input (focus)="inputFocused('subject', true)"  [(ngModel)]="currentNotification().subject"
              placeholder="Subject" #subject name="subject" id="subject" class="input is-fullwidth" [attr.disabled]="isEditingDisabled() ? '' : null">
          </div>

        </div>

      </div>
      <div class="var-instruction">
        <span>
          Example: %T %C : %S Notification = "Accrual at 50%: MC1365 Notification"
        </span>
      </div>
    </div>
  </div>

  <div class="columns" *ngIf="currentNotification()">
    <div class="column is-1">
      <div class="field is-horizontal ">
        <label>Body</label>
      </div>
    </div>
    <div class="column is-11">
      <div class=" text-field-padding ">
        <div class="  is-horizontal ">
          <p class="control " *ngIf="isEditingDisabled()" [innerHTML]="currentNotification().messageText">
          </p>
          <div class="control " *ngIf="!isEditingDisabled()">
            <quill-editor #body name="body" id="body" [(ngModel)]="currentNotification().messageText" (onSelectionChanged)="editorSelectionChanged($event)"
             (onEditorCreated)="editorCreated($event)" [style]="{height: '250px', background: 'white'}">
            
              <div id="toolbar-container"  quill-editor-toolbar>                
                 <!-- Text Size  -->
                <span class="ql-formats">
                  <select class="ql-font"></select>
                  <select class="ql-size"></select>
                </span>
                <!-- Text Formatting  -->
                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                  <button class="ql-strike"></button>
                </span>
                <span class="ql-formats">
                  <select class="ql-color"></select>
                  <select class="ql-background"></select>
                </span>
                  <!-- Subscript/superscript  -->
                <span class="ql-formats">
                  <button class="ql-script" value="sub"></button>
                  <button class="ql-script" value="super"></button>
                </span>
                <!-- Text Block format -->
                   <!-- Header Sizes  -->
                <span class="ql-formats">
                  <button class="ql-header" value="1"></button>
                  <button class="ql-header" value="2"></button>
                  <!-- <button class="ql-blockquote"></button>
                  <button class="ql-code-block"></button> -->
                </span>
                 <!-- Ordered List / Bullets   -->
                  <!--  Indenting -->
                <span class="ql-formats">
                  <button class="ql-list" value="ordered"></button>
                  <!-- <button class="ql-list" value="bullet"></button> -->
                  <button class="ql-indent" value="-1"></button>
                  <button class="ql-indent" value="+1"></button>
                </span>
               <span class="ql-formats">
                 <!--   <button class="ql-direction" value="rtl"></button> -->
                  <select class="ql-align"></select>
                </span>
                <span class="ql-formats">
                  <button class="ql-link"></button>
                  <button class="ql-image"></button>
                  <button class="ql-video"></button>
                  <!-- <button class="ql-formula"></button> -->
                </span>
                <!-- <span class="ql-formats">
                  <button class="ql-clean"></button>
                </span> -->
              </div>
           
            </quill-editor>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<mc-info-modal  [title]="modalTitle" [body]="modalBody"></mc-info-modal>